
import BasicLayout from '~/layouts/basic'
import { mapState, mapActions } from 'pinia'
import { useUiStore } from '~/store/ui'
import { usePopsEventsStore} from '~/store/pops/events';
import { useIntegrationsStore } from '~/store/integrations'
import { useSiteStore } from '~/store/site'

export default {
    head() {
        return {
            title: 'SalesPop | Pop Events',
        }
    },
    components: {
        BasicLayout
    },
    computed: {
        ...mapState(useUiStore, ['loadingPreflight']),
        ...mapState(useSiteStore, ['currentSite']),
        ...mapState(useIntegrationsStore, ['sourceIntegrationHandles']),
        ...mapState(usePopsEventsStore, ['events'])
    },
    data: ()=>({
        selectMode: false,
        selectedList: [],
        refreshInterval: null
    }),
    mounted() {
        if(this.refreshInterval === null) {
          this.refreshInterval = setInterval(this.fetchPopEvents, 25000)
        }
        this.fetchPopEvents()
    },
    beforeDestroy() {
      clearInterval(this.refreshInterval)
      this.refreshInterval = null
    },
    methods: {
        ...mapActions(usePopsEventsStore, ['fetchPopEvents', 'deletePopEvent']),
        ...mapActions(useIntegrationsStore, ['integrationTitle']),
        handleChangeSelected(list){
            this.selectedList = list
        },
        async handleDeleteList(){
            const promises = this.selectedList.map(event => this.deletePopEvent(event.id))
            try {
                await Promise.all(promises)
            } catch(e){
                console.error(e)
            }
            this.fetchPopEvents()
            this.selectMode = false
            this.selectedList = []
        },
    }
}
