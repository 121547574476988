import { render, staticRenderFns } from "./events.vue?vue&type=template&id=ec07d868&scoped=true&lang=pug"
import script from "./events.vue?vue&type=script&lang=js"
export * from "./events.vue?vue&type=script&lang=js"
import style0 from "./events.vue?vue&type=style&index=0&id=ec07d868&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec07d868",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/opt/build/repo/components/DataInput/Button.vue').default,EventsTable: require('/opt/build/repo/components/business/EventsTable.vue').default,OnePopEvent: require('/opt/build/repo/components/business/OnePopEvent.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default,EmptyPopEvents: require('/opt/build/repo/components/EmptyState/EmptyPopEvents.vue').default})
