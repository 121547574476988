
import { mapActions, mapWritableState, mapState } from 'pinia'
import { useSiteStore } from '~/store/site'
import { useIntegrationsStore } from "~/store/integrations";
import { usePopsEventsStore } from "~/store/pops/events";

export default {
    props: {
        shopPopEvents: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        selectMode: {
            type: Boolean,
            default: () => false
        }
    },
    data: () => ({
        selected: []
    }),
    computed: {
        ...mapWritableState(usePopsEventsStore, ['recentlyDeletedEvents']),
        ...mapState(useSiteStore, ['currentSite']),
    },
    watch: {
        selectMode(selectMode) {
            if(!selectMode) this.selected = []
            this.$emit('change-selected', this.selected)
        }
    },
    methods: {
        ...mapActions(useIntegrationsStore, ['integrationTitle']),
        handleDelete(id){
            this.$emit('delete', id)
        },
        handleSelect(event, mouseEvent){
            if(mouseEvent.type === 'mouseenter' && mouseEvent.buttons !== 1) return
            const active = new Set(this.selected)
            if(active.has(event)){
                active.delete(event)
            } else{
                active.add(event)
            }
            this.selected = [...active]
            this.$emit('change-selected', this.selected)
        },
    }
}
