
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '~/store/user'
import { useUiStore } from "~/store/ui";

export default {
  layout: 'loading',
  watch: {
    loadingPreflight: {
      immediate: true,
      handler(loading) {
        if (loading) return

        this.$router.push('/dashboard')
      }
    },
  },
  mounted() {
    this.setUserAuthToken()
    this.setAdminSessionToken()
  },
  methods: {
    ...mapActions(useUserStore, ['setAuthToken', 'setAdminToken']),
    setUserAuthToken() {
      const token = this.$route.query.token
      if (token) {
        this.setAuthToken(token)
      } else {
        window.top.location.href = 'https://salespop.com'
      }
    },
    setAdminSessionToken() {
        const admin_token = this.$route.query.admin_token

        if(admin_token) {
            this.setAdminToken(admin_token)
        }
    }
  },
  computed: {
    ...mapState(useUiStore, ['loadingPreflight']),
  }
}
